/*
@import '../../styles/colors.scss';
*/
em {
  font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-style: italic; }

b,
strong,
th {
  font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: bold; }

.faded {
  opacity: 0.5; }

.success {
  fill: #4aff9e;
  color: #4aff9e; }

.error {
  fill: #9f3a38;
  color: #9f3a38; }

body {
  margin: 0 !important; }

.page {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 2em; }

.page.modals {
  max-width: 100%; }

h1,
h2,
h3 {
  font-weight: normal; }

h2 {
  color: rgba(0, 0, 0, 0.9);
  margin-top: 0rem;
  font-size: 1.5rem; }

h3 {
  color: rgba(0, 0, 0, 0.8);
  margin-top: 0rem;
  font-size: 1.4rem; }

.alignRight {
  float: right; }

.App__TestBuildLabel {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 100;
  cursor: pointer; }
  .App__TestBuildLabel p {
    margin-bottom: 5px !important; }

.link {
  cursor: pointer;
  color: #3c80c3; }

.clickable {
  cursor: pointer; }

.wide-page {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto; }

.wrapper {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }

.Banner {
  background: #26282c;
  background-position: center;
  background-repeat: no-repeat;
  height: 20px; }

.ui.dimmer {
  z-index: 501 !important;
  background-color: rgba(0, 0, 0, 0.95) !important; }

.loader {
  z-index: 50; }

.ui.menu .item--alert {
  background: #3c80c3 !important;
  color: #ffffff !important; }

.answer {
  margin: 1em; }
  .answer img {
    max-width: 100%; }

.clearfix:after {
  clear: both;
  content: '.';
  display: block;
  height: 0;
  visibility: hidden; }

.clearfix {
  display: block;
  clear: both; }

.highlight {
  background: #d92b2f !important;
  color: #ffffff !important; }

.calendar {
  height: 800px;
  margin-bottom: 50px;
  margin-top: 50px; }

.calendar-toolbar {
  margin-bottom: 1em; }
  .calendar-toolbar .view-type {
    float: right; }
  .calendar-toolbar img {
    cursor: pointer; }

.calendar-label .label {
  font-size: 1em;
  vertical-align: 100%; }

.calendar-details-content {
  font-size: 1.1em;
  margin-bottom: 1em; }

.data-count {
  display: inline-block; }

.class-group-details .column {
  word-wrap: break-word; }

.view-type .icon {
  font-size: 1em; }

.view-type .icon.active {
  background: #3c80c3; }

td.avatar-lock-up {
  padding: 0 !important;
  border: none !important; }

.select-lesson-form {
  padding: 4em;
  background: #f7f7f7;
  border-bottom: 4px solid #eaeaea;
  margin-bottom: 2em; }

.header.editor .menu {
  box-shadow: none;
  border-radius: 0;
  border-width: 0px; }

.show-filters-btn.button {
  margin-bottom: 1em !important; }

.manage-lesson-list-icons a {
  display: inline-block; }

.ui.form .field.error input {
  background: #fff6f6 !important;
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
  box-shadow: none !important; }

.page-wrapper {
  z-index: 1;
  position: relative;
  height: 100%; }

.full-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column; }
  .full-container.default {
    background: #ffffff; }
  .full-container.blue {
    background: #00b1ef; }

@keyframes float {
  0% {
    transform: translatey(0px); }
  50% {
    transform: translatey(-10px); }
  100% {
    transform: translatey(0px); } }

.Animation--float {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite; }

.Table--fixedHeaders thead tr > th {
  position: sticky !important;
  top: 0;
  z-index: 11;
  background: #ffffff; }

.Table--fixedHeaders thead tr > td {
  position: sticky !important;
  top: 3.3em;
  z-index: 10;
  background: #ffffff;
  border-top: 0 !important;
  padding: 0 !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1); }

.Cumulio__Iframe {
  border: 0;
  width: 100%;
  height: 40vh; }
