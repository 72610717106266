/*
@import '../../styles/colors.scss';
*/
.NotificationModal {
  position: absolute;
  right: 10px;
  top: 10px;
  position: fixed;
  z-index: 200; }
  .NotificationModal .message {
    border: none;
    box-shadow: none;
    max-width: 300px; }
  .NotificationModal--error .message {
    color: #eaeaea !important;
    background: #d92b2f !important; }
  .NotificationModal--success .message {
    color: #eaeaea !important;
    background: #21ba45 !important; }

#InputField__TextContainer {
  padding-top: 0%;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

#InputField__TextArea {
  max-height: 125px;
  min-height: 30px;
  height: 50px;
  min-width: 100%;
  max-width: 100%; }
