@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

html,
body,
#root {
  font-size: 16px !important;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}
