/*
@import '../../styles/colors.scss';
*/
$black: #000000;
$white: #ffffff;
$outer-space: #26282c;
$shark: #222426;
$athens-grey: #f9fafb;
$tundora: #4a4a4a;
$silver-sand: #cacbcd;
$punch: #d92b2f;
$concrete: #f2f2f2;
$boston-blue: #3c80c3;
$semantic-blue: #2185d0;
$alabaster: #f7f7f7;
$gallery: #eaeaea;
$red: #ff0000;
$japanese-laurel: #008800;
$dove-grey: #646464;
$yellow: #ffff00;
$mine-shaft: #262626;
$mountain-meadow: #21ba45;
$pigeon-post: #9fbcda;
$gossip: #c9facb;
$slate-grey: #72828b;
$chablis: #fff6f6;
$grey: rgba(34, 36, 38, 0.1);
$iron: #e0e1e2;
$mariner: #2179d0;
$sushi-green: #7bc043;
$LoginForm: #00adf0;
$button-bg: #4183c4;
$button-lb: #00adf0;
$input-bg: #dadada;

//FTRPRF
$accent-lesson: #5f992e;
$accent-exam: #00adf0;
$accent-exercise: #fab82e;
$accent-student: #ea2c20;
$accent-hackroom: #72828b;
$accent-class: #72828b; //temp
$accent-teacher: #72828b; //temp
$accent-program: #72828b; //temp
$accent-school: #72828b; //temp
$accent-smartschool: #72828b; // temp
$accent-profile: #72828b; // temp
$accent-scratch: #fab82e; //temp
$accent-slideviewer: #33caff;
$ftrprf-blue: #00b1ef;
$ftrprf-green: #4aff9e;
$ftrprf-red: #ff4848;
$ftrprf-orange: #fab82e;
$ftrprf-error: #9f3a38;
$ftrprf-correct: #70d949;
$ftrprf-wrong: #fb5384;

//Scratch-General
$sc-extra-green: #008000;
$sc-extra-blue: #0000ff;
$sc-extra-red: #ff0000;
$sc-menu-bar: #4c97ff;

//Scratch2-Blocks
$sc-beweging: #4a6cd4;
$sc-uiterlijk: #8a55d7;
$sc-geluid: #bb42c3;
$sc-pen: #0e9a6c;
$sc-data: #ee7d16;
$sc-gebeurtenissen: #c88330;
$sc-besturen: #e1a91a;
$sc-waarnemen: #2ca5e2;
$sc-functies: #5cb712;
$sc-meer-blokken: #632d99;
$sc-parameters: #5947b1;
$sc-lijsten: #cc5b22;

//Scratch3-Blocks
$sc3-beweging: #4c97ff;
$sc3-uiterlijk: #9966ff;
$sc3-geluid: #cf63cf;
$sc3-pen: #0fbd8c;
$sc3-data: #ff8c1a;
$sc3-gebeurtenissen: #ffbf00;
$sc3-besturen: #ffab19;
$sc3-waarnemen: #5cb1d6;
$sc3-functies: #59c059;
$sc3-meer-blokken: #ff6680;
$sc3-parameters: #ff6680;
$sc3-lijsten: #ff661a;

//Javascript
$js-basic: #1d78d7;
$js-input: #b6409e;
$js-music: #d83d2f;
$js-led: #5c2d91;
$js-radio: #e5478c;
$js-loops: #3f7d14;
$js-logic: #2d6a70;
$js-variables: #a92b22;
$js-math: #712872;
$js-functions: #165b9e;
$js-arrays: #a94422;
$js-text: #99661c;
$js-game: #3d8372;
$js-images: #5c2d91;
$js-pins: #a92b22;
$js-serial: #002050;
$js-control: #333333;

//Minecraft
$mc-player: #0078d7;
$mc-blocks: #7abb55;
$mc-mobs: #764bcc;
$mc-agent: #d83b01;
$mc-builder: #cb48b7;
$mc-gameplay: #8f6d40;
$mc-positions: #69b090;
$mc-loops: #569138;
$mc-logic: #459197;
$mc-variables: #ea2b1f;
$mc-math: #6c6ea0;
$mc-advanced: #3c3c3c;
$mc-functions: #235789;
$mc-arrays: #a94400;
$mc-text: #e89005;
$mc-shapes: #ec7505;
$mc-extensions: #717171;
