/*
@import '../../styles/colors.scss';
*/
.ErrorPage {
  height: 100vh;
  text-align: center; }
  .ErrorPage__Message {
    position: relative;
    vertical-align: top;
    top: 40%;
    transform: translateY(-50%); }
    .ErrorPage__Message h1 {
      font-size: 8rem !important;
      font-weight: 900;
      color: #ff0000; }
    .ErrorPage__Message h2 {
      font-size: 2rem !important; }
    .ErrorPage__Message__Text__Left {
      text-align: left !important; }
    .ErrorPage__Message__Text__Center {
      text-align: center !important; }
    .ErrorPage__Message__IconWrapper {
      text-align: center !important; }
    .ErrorPage__Message__Link {
      color: #000000 !important; }
    .ErrorPage__Message__Label {
      background-color: #ff0000 !important;
      color: #ffffff !important; }
